@tailwind base;
@tailwind components;

:root {
  --primaryColour: #374151;
  --secondaryColour: #000;
  --backgroundColour: #fff;
  --panelBackgroundColour: #F3F4F6;
}

body {
  margin: 0;
  font-family: -apple-system,system-ui,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @apply text-primaryColour bg-backgroundColour;
  @apply h-screen;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.heading {
  @apply text-xl font-bold;
}

.title {
  @apply text-lg font-semibold;
}

.header {
  @apply text-lg font-semibold;
}

.regular {
  @apply text-base font-normal;
}

.subtitle {
  @apply text-sm font-light;
}
