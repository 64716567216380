@tailwind base;
@tailwind components;

.mangaimage-container {
  @apply flex flex-col items-center;
}

.FitHeight {
  @apply h-screen;
}
.FitWidth {
  @apply w-screen;
  max-width: 100%;
}
