@tailwind base;
@tailwind components;
@tailwind utilities;

.menu {
  @apply w-80;
  @apply bg-panelBackgroundColour dark:bg-gray-800 shadow-lg;
  @apply flex flex-col p-6 gap-y-6 transition-all duration-300;
  @apply min-h-full;
}

.menu-closed {
  @apply -ml-80;
  @apply overflow-y-hidden;
}

.vertical-center {
  top: 50%;
  transform: translateY(-50%);
}

.dropdown {
  @apply p-2 text-primaryColour bg-panelBackgroundColour border rounded-md border-primaryColour;
}

.menuWrapper {
  @apply fixed -inset-y-0 left-0 z-20 overflow-y-auto overflow-x-hidden;
}

.control-title {
  @apply flex flex-row gap-x-2 content-center items-center;
}
.control {
  @apply flex flex-col gap-y-2;
}
